 /* eslint-disable  @typescript-eslint/no-explicit-any */
 /* eslint react-hooks/exhaustive-deps: "off" */
 /* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react'
import { QuickTransfer } from './QuickTransfer';
import { FxAccountInfoCard } from '../../Cards/FxAccountInfoCard';
import { Grid, Typography } from '@mui/material';
import { AccountListCard } from './AccountListCard';
import { getCustomerUrl, useIsMobileOrTabletScreen, useIsMobileScreen } from '../../../../libs/utils/utils';
import { getKey } from '../../../../libs/utils/storageManager';
import { useDispatch, useSelector } from 'react-redux';
import FxTopBanner from '../../../Utils/fx-top-banner';
import UIFeatureImpl from '../../../../libs/services/uiFeatures';
import { checkPermission, getTargetBalancePrefrences, updateComponentParams } from '../../../Utils/CommonBaseClass';
import { ReactComponent as LightningBoltIcon } from '../../../../assets/svg/Money_Market.svg'
import FxCardBody from '../../../Container/FxCardBody';
import FxCard from '../../../Container/FxCard';
import FxCardHeader from '../../../Container/FxCardHeader';
import { useHistory } from 'react-router';

/**
 * Component used to draw dashboard of accounts
 */
export const DashboardAccountHome: React.FC<any> = React.memo((props) => {

    const isMobileScreen = useIsMobileOrTabletScreen();
    const isSmallScreen = useIsMobileScreen();
    const customerStatus = getKey("customerStatus");
    const uiFeat = UIFeatureImpl.getInstance();
    const businessCustomer = uiFeat.getBusinessCustomerFeature().available;
    const jointTenancy= uiFeat.getJointTenancyFeature().available;
    const [showCashBuilderCard, setShowCashBuilderCard] = useState(false);
    const [showCashBuilderPlusCard, setShowCashBuilderPlusCard] = useState(false);
    let bannerCarouselData:any = [];
    const dispatch = useDispatch()
    const history = useHistory();

    /**
     * UseEffect to close all topbaner when initializing the component
     */
    useEffect(()=>{
        FxTopBanner.closeForce('faster_funding_banner');
        FxTopBanner.closeForce('deposit_account');
        FxTopBanner.closeForce('cash_builder_plus_account');
        getTargetBalancePrefrences(getCustomerUrl("preference"), dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    /**
     * to get source details
    */
    const sourceInfo = useSelector((state: any) => {
        if (state.data['customer-basic-info']) {
            return state.data['customer-basic-info']
        }
    });

    const accountList = sourceInfo?.params?.accountList?.resources;
    const activeAccountStatus = accountList?.some((item:any) => (item.type !== 'CASH_BUILDER_PLUS' || item.type !== 'CASH_BUILDER') && item.status === 'ACTIVE');
    const hasInvestmentAccount = sourceInfo?.params?.hasInvestmentAccount;     
    const hasCashBuilderAccount = sourceInfo?.params?.hasCashBuilderAccount;  

    /**
     * to show/hide investment account banner 
     */
    const canShowInvestmentBanner = useMemo(() => {
        if(businessCustomer && activeAccountStatus && !hasInvestmentAccount){
            return true;
        }else{
            return false;
        }
    },[businessCustomer, activeAccountStatus, hasInvestmentAccount]);
    
    const isTargetBalanceEnabled = useSelector((state: any) => (state.data['target-balance-enabled']?.data?.isTargetBalanceEnabled || null));
    
    /**
    * to show/hide Cash Builder+ account banner 
    */
    const canShowCashBuilderBanner = useMemo(() => {
        return !jointTenancy && activeAccountStatus && !hasCashBuilderAccount && customerStatus === 'ACTIVE';
    },[jointTenancy, activeAccountStatus, hasCashBuilderAccount, customerStatus]);


    /**
     * useEffect to load fast funding banner
     */
    useEffect(() => {

        //  to open Cash Builder+ Banner
        if(canShowCashBuilderBanner && getKey('enableCashBuilderAccount') === 'true' && getKey('role') === 'ADMIN'){
            setShowCashBuilderPlusCard(true);
        }
        
        //  to open Cash Builder Banner
        if(canShowInvestmentBanner && getKey('enableInvestmentAccount') === 'true' && getKey('role') === 'ADMIN'){
            setShowCashBuilderCard(true);
        }


        if(bannerCarouselData.length > 0){
            updateComponentParams(dispatch, 'customer-basic-info', { 'topBannerData': bannerCarouselData })
            FxTopBanner.storeData(bannerCarouselData);
          }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [canShowInvestmentBanner, isMobileScreen, canShowCashBuilderBanner])

    const account_source = {
        url: "account/list",
        method: "POST",   
        data: {
    
            "pageSize": 25,
            "pageNumber": 1,
            "sortOptions": {
                "sortOrder": "ASC",
                "sortBy": "id"
            },
            "criteria": {
                "filters": [
                    {
                        "key": "insightPeriod",
                        "operator": "eq",
                        "values": [
                            "MONTHLY"
                        ]
                    }
                ]
            }
        }
    };
    const createTBAPermission = {
        entity:"Business Customer",
        name:"Preference",
        operation:"CREATE"
      }

    return (
        <Grid container xs={12} alignItems="flex-start" justifyContent="space-between" className={"fx-passport-home fx-container"} >
            <Grid item container xs={12} className="fx-container-left">
                <AccountListCard id="account-list-sub-menu" source={account_source} showCashBuilderCard={showCashBuilderCard} showCashBuilderPlusCard={showCashBuilderPlusCard} 
                treasuryAccordionOpen={true} targetBalanceAccordionOpen={true}/>
                {isSmallScreen && <QuickTransfer id="home-quick-transfer-card" />}
            </Grid>
            {!isSmallScreen && <Grid container className="fx-container-right">
                <QuickTransfer id="home-quick-transfer-card" />

                {
                    getKey('userType') === 'BUSINESS' && (isTargetBalanceEnabled) && getKey('enableTargetBalanceAccount') === 'true' && checkPermission(createTBAPermission) &&
                    <FxCard
                        id="customer-target-balance-accounts-card"
                        className="fx-card-general fx-theme-passport fx-customer-account-list fx-no-padding"
                    >
                        <FxCardHeader className='fx-color-light-orange fx-theme-passport-reduced-padding' id="create-del-header">
                            {<Grid item xs={12} container alignItems="center" className={'fx-account-info-icon'}>
                                {<LightningBoltIcon />}
                                <Grid item>
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', ml: 1 }}>Target Balance Service</Typography>
                                </Grid>
                            </Grid>}
                        </FxCardHeader>
                        <FxCardBody
                            id="customer-target-balance-account-home-card-Body"
                            className="fx-card-body fx-target-balance-modal-tbr-header"
                        >
                            <FxAccountInfoCard
                                id={props?.id}
                                accounts={[
                                    {
                                        id: "target-balance-service",
                                        description: "Effortlessly optimize your funds with automatic balance sweeps, ensuring your money is always in the right place at the right time.",
                                        subdescription: "Seamless cash management that works for you!",
                                        icon: <LightningBoltIcon />,
                                        buttonText: "Enable Now",
                                        callback: () => {history.push('/target-balance-service')}
                                    }
                                ]}
                            />
                        </FxCardBody>
                    </FxCard>
                }
            </Grid>}
        </Grid>
    )
})
