/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'; // we need this to make JSX
import { Logger } from '../../../libs/utils/logger';
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { getKey, getSessionStorageCustom, isUserLoggedIn, setKey } from '../../../libs/utils/storageManager';
import HttpClient from '../../../libs/utils/httpClient';
import { getLocalStorage, setLocalStorage } from '../../../libs/utils/cacheHandler';
import { getUserRefreshProperties, setVerificationStatus } from '../../Utils/CommonBaseClass';
import { checkPermission, getDefaultCustomer, getRedirectInfo, updateComponentParams } from '../../Utils/CommonBaseClass';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterComponent } from '../../../libs/saga/dataSaga';
import { checkIsPartner } from '../../Utils/ThemeCheckerPartner';
import { getCustomerUrl } from '../../../libs/utils/utils';
import { DashboardSkeleton } from '../Index/Passport/DashboardSkeleton';
import { applyCustomFeatures } from '../../../libs/services/uiFeatures';
import FxSnackBar from '../../Utils/fx-snack-bar';
import _ from 'lodash';
import FxTopBanner from '../../Utils/fx-top-banner';
import { ThemeEnum } from '../../../types/common.interfaces';


/**
 * Component: KeycloakPostLogin
 * Usage: launch after keycloak login
 */

Logger.debug("Login.tsx", "User logged in KeycloakPostLogin initializing")
const httpClient = HttpClient.getClient();


declare global {
    interface Window {
        README_CONFIG: object;
    }
  }
  const readMeConfig: any = window.README_CONFIG;

export const KeycloakPostLogin: React.FC<any> = (props: any) => {
    ({ props } = RegisterComponent(props));
    const history = useHistory();
    const params = useParams<any>();
    const location = useLocation();
    const dispatch = useDispatch()
    let queryParams: any;
    let customerId:any;
    let customerEmail:any;
    const [userinfo, userDetails] = React.useState<any>();
    const mxmUser=JSON.parse(getLocalStorage('mxmUser'))
    const ssoPath=location?.pathname?.includes('/sso')
    const reloadPath = getSessionStorageCustom('reloadPath');
    const locationState = getSessionStorageCustom('locationState');
    const isUserRefreshed = getSessionStorageCustom("isUserRefreshed");

    /**
     * Method to get selected account from store only when refresh action is done
     */
    const selectedAccount = useSelector((state: any) =>
        state.data['account-list-sub-menu']?.params?.selectedOption
    );

    /**
    * Method to get top banner data when refresh action is done from store and store it in FxTopBanner if available
    */
    const topBannerData = useSelector((state: any) =>
        state.data['customer-basic-info']?.params?.topBannerData
    );

    if (topBannerData && topBannerData.length > 0) {
        FxTopBanner.storeData(topBannerData);
    }

    /**
     * UseEffect to load the next page based on account and user permissions
     */
    useEffect(() => {
        isUserRefreshed && getUserRefreshProperties(selectedAccount, dispatch)
        applyCustomFeatures(props?.customerConfigurations)
        if (getKey("permissionDenied") === 'True') {
            history.push("user/permission/denied");
        } 
        /**
         * When the user sign the login terms and may api faild for the  Account Agreement then user should redirct to Account Agreement Accepted screen if he login again
         */
    
       
        else if (getKey('userType')==='JOINT_TENANCY' && getKey("showTerms") === 'NO' && getKey('accountAgreementAccepted') === false && props?.accountList?.resources?.length>0 ) {
            const payload:any={
                account:props?.accountList?.resources[0],
               
            }
            dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'co-owner-aggrement-details' } });
            updateComponentParams(dispatch, 'co-owner-aggrement-details', { 'ownerPayload': payload })
            history.push('/co-owner/welcome')
        }
        else if (getKey("showTerms") === 'YES') {
            history.push("/auth/terms/" + getKey('id'));
        }
        else if(getKey("showTerms") === 'NO' && props.skipSkeleton && props.customerConfigurations) {
            if (isUserLoggedIn() && reloadPath && reloadPath !== '/logout' && isUserRefreshed) {
                updateComponentParams(dispatch, 'customer-home-passport', { 'accountUpdated': true })
                const redirectInfo = getRedirectInfo(props?.accountList, dispatch, reloadPath, selectedAccount, true);
                history.push(locationState ? { pathname: redirectInfo, state: locationState } : redirectInfo);
            }
            else if(checkIsPartner() && (checkPermission({ entity: "Partner Portal", name: "Lead Portal", operation: "View" }))){
                history.push(getRedirectInfo(props?.accountList, dispatch ,'/leads-home'))
            }
            else{
                history.push(getRedirectInfo(props?.accountList, dispatch, '/dashboard'))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userinfo, history, props.skipSkeleton, props.customerConfigurations])

    /**
     * useEffect to check proxy login token
     */
    useEffect(() => {
        if (ssoPath) {
            setSSOUser()
        }
        else if (params?.token) {
            setProxyUser()
        }
        else {
            getUserInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /**
     * function to set proxy login value
     */
    const setProxyUser = () => {
        if (params?.token) {
            setLocalStorage('proxyToken', params?.token)
            queryParams = window.atob(params['token']);
        }
        try {
            queryParams = JSON.parse(queryParams)
        } catch (e: any) {
            Logger.error("KeycloakPostLogin.tsx", "error", e);
         }
        setKey("customerId", queryParams?.customerId);
        queryParams?.partnerId && setKey("partnerId", queryParams?.partnerId);
        queryParams?.partnerId && setKey('loginType', 'PARTNER');
        setLocalStorage("isProxyLogin", true);
        setKey("isProxyLogin", true);
        customerId = queryParams?.customerId
        setLocalStorage("permission", 'ADMIN');
        setKey("goBackPortal", queryParams?.portal);
        setKey("goBackUrl", queryParams?.referrer && decodeURIComponent(queryParams?.referrer));
        updateComponentParams(dispatch, 'layout-passport', { 'showTerms': 'NO' })
        setKey("showTerms", "NO");

        //update http client for custom headers
        HttpClient.updateClient();
        getUserInfo();
    }
    
    /**
    * function to set sso login value
    */
    const setSSOUser = () => {
        if (params?.token) {
            setLocalStorage('proxyToken', params?.token)
            queryParams = window.atob(params['token']);
        }
        try {
            queryParams = JSON.parse(queryParams)
        } catch (e: any) {
            Logger.error("KeycloakPostLogin.tsx", "error", e);
         }
        updateComponentParams(dispatch, 'app-global-store', { 'theme': 'mxm' })
        setLocalStorage("MXMSsoLogin", true);
        setKey("goBackPortal", 'MXM');
        setKey("goBackUrl", queryParams?.referrer && decodeURIComponent(queryParams?.referrer));
        setKey("goPriorityCapitalUrl", queryParams?.redirectPath && decodeURIComponent(queryParams?.redirectPath));
        updateComponentParams(dispatch, 'layout-passport', { 'showTerms': 'NO' })
        setKey("showTerms", "NO");
        getUserInfo();
    }

    const settingsReq: any = {
        "criteria": {
            "filters": [
                {
                    "operator": "in",
                    "key": "settingName",
                    "values": [
                        "ALLOW_CORPORATE_PHYSICAL_DEBIT_CARD_ISSUANCE",
                        "ALLOW_COLLECT_WITH_SOURCE_DETAILS",
                        "MANDATE_CARD_INFO_FOR_CARD_TRANSACTION",
                        "ALLOWED_METHODS_IN_PPI",
                        "ALLOW_SEND_VIA_VIRTUAL_CARD",
                        "ALLOW_CONSUMER_PHYSICAL_DEBIT_CARD_ISSUANCE",
                        "APPLY_NEW_CARD_STATUS_LIFECYCLE",
                        "INTERNAL_PROGRAM_MANAGER"
                    ]
                }
            ]
        }
    }

    const themeSettingsRequest: any = {
        "criteria": {
            "filters": [
                {
                    "operator": "in",
                    "key": "settingName",
                    "values": [
                        "CUSTOMER_PORTAL_THEME"
                    ]
                }
            ]
        }
    }
    

    const listRequest =
    {
        "pageSize": 25,
        "pageNumber": 1,
        "sortOptions": {
            "sortOrder": "DESC",
            "sortBy": "createdOn"
        },
        criteria: {
            filters: []
        }
    };

    const quick_settle_settings_request = {
        "pageNumber": 1,
        "pageSize": 1,
        "sortOptions": {
            "sortBy": "lastUpdatedOn",
            "sortOrder": "desc"
        },
        "criteria": {
            "filters": [
                {
                    "operator": "eq",
                    "key": "category",
                    "values": [
                        "QUICK_SETTLE"
                    ]
                },
                {
                    "operator": "in",
                    "key": "entityName",
                    "values": [
                        "ACCOUNT", "MERCHANT"
                    ]
                }

            ]
        }
    }    

    const merchant_request = {
        pageNumber: 1,
        pageSize: 25,
        criteria: {
            filters: []
        }
    }


  /**
     * Method used to get user informations from API
     * @returns :user information
     */
    async function getUserInfo() {
        try {
            let userTypeData: any;
            let accountList: any;
            let pmSettings: any;
            let sessionData: any;
            let quickSettleSettings: any;
            let readMeToken: any;
            let customerConfigurations: any;
            let customerMerchantWhenProxyLogin: any;
            const response = await getPMProcessingSettings();
            const customerSettings = response?.data;
            //PM level setting used to check the theme set Per PM, default will be “customer orange theme”
            const pmSettingValue = getPmSettingsValue(customerSettings, "CUSTOMER_PORTAL_THEME");
            setLocalStorage("customerTheme", pmSettingValue)
            if(queryParams?.portal!=='CSG' &&  queryParams?.portal!=='PM'){
                if( mxmUser && !ssoPath && (pmSettingValue !== ThemeEnum.ORANGE_THEME)){
                    updateComponentParams(dispatch, 'app-global-store', { 'theme': 'mxm' })
                }
                const profileData = await httpClient.get('profile').then(response => {
                    setKey("permissionDenied", 'False');
                    return response.data
                }).catch(error => {
                    setKey("permissionDenied", 'True');
                    return { ...error };
                })

                /**
                 * if the response is 401 then it should be redirected into login page
                 */
                if(profileData && profileData?.response?.status===401){
                    FxSnackBar.show({
                        autoHideDuration: 3000,
                        severity: 'error',
                        text: profileData?.response?.data?.errorMessage
                    });
                    setTimeout(() => {
                        history.push("/logout");
                      }, 3000); 
                }

                //If profile details available settings user based keys
                if (profileData) {
                    userDetails(profileData?.id)
                    setKey('firstName', profileData.firstName);
                    setKey('loginType', profileData.userType);
                    setKey("id", profileData?.id);
                    setKey("countryCode", profileData?.countryCode);
                    setKey("email", profileData?.email);
                    customerEmail=profileData?.email;
                    setKey("lastName", profileData?.lastName);
                    setKey("phone", profileData?.phone);
                    setKey("userName", profileData?.userName);
                    setKey('partnerId',profileData?.customer[0]?.programAffiliate?.id);
                    setKey("customerInitialMerchantId",profileData?.customer?.[0]?.merchant?.[0]?.id);

                    if(!JSON.parse(getLocalStorage('MXMSsoLogin'))){
                        setKey("showTerms", (profileData.privacyPolicyAccepted !== undefined && profileData.privacyPolicyAccepted) ? 'NO' : 'YES');
                        updateComponentParams(dispatch, 'layout-passport', { 'showTerms': (profileData.privacyPolicyAccepted !== undefined && profileData.privacyPolicyAccepted) ? 'NO' : 'YES' })
                    }
                    setKey("userType", String(profileData?.customer?.[0]?.subType));
                    customerId = getDefaultCustomer(profileData?.customer, queryParams?.xmid)?.id;
                    const customer = profileData?.customer?.find((item: any) => item.id === customerId);
                    setKey("authUserId", customer?.authorizedUser?.id);
                    setKey("accountAgreementAccepted", customer?.authorizedUser?.accountAgreementAccepted);
                    setKey('showBatch', customer?.merchant?.length > 0)
                    setKey("customerId", customerId);
                    setLocalStorage("permission", profileData?.customer?.[0]?.permissions)
                    setKey("programManagerId",profileData?.customer?.[0]?.programManagerId)
                    updateComponentParams(dispatch, 'switch-customer-menu', {customerList: profileData?.customer})
                    if(profileData?.customer?.[0]?.role?.some((item:any) => item.isGlobalEntityAccess === true && item.isGlobalObjectAccess === true)){
                        setKey('role','ADMIN');
                    }else{
                        setKey('role','NON_ADMIN');
                    }
                }
            }
            //Calling multiple API's od customer when loggedIn succesfully
            const result = await Promise.allSettled([getSession(), getCustomerDetails(), getUserAccount(), getProcessingSettings(), getQuickSettleSettings(), getReadmeToken(customerEmail), getCustomerConfiguration(), getCustomerMerchantWhenProxyLogin() ]).then(function (data: any) {
                if (data?.[0]?.status === "fulfilled") {
                    sessionData = data?.[0]?.value?.data
                } if (data?.[1]?.status === "fulfilled") {
                    userTypeData = data?.[1]?.value?.data
                } if (data?.[2]?.status === "fulfilled") {
                    _.isNull(isUserRefreshed) && dispatch({ type: "DATA_COMPONENT_INIT", payload: { id: 'customer-basic-info' } });
                    updateComponentParams(dispatch, "customer-basic-info", { 'accountList': data?.[2]?.value?.data })
                    accountList = data?.[2]?.value?.data
                } if (data?.[3]?.status === "fulfilled") {
                    pmSettings = data?.[3]?.value?.data
                } if (data?.[4]?.status === "fulfilled") {
                    quickSettleSettings = data?.[4]?.value?.data
                } if (data?.[5]?.status === "fulfilled") {
                    readMeToken = data?.[5]?.value?.data?.jwtToken
                } if (data?.[6]?.status === "fulfilled") {
                    customerConfigurations = data?.[6]?.value?.data
                } if (data?.[7]?.status === "fulfilled") {
                    customerMerchantWhenProxyLogin = data?.[7]?.value?.data
                }
                if (readMeToken) {
                    setKey("readMeToken", readMeToken);
                }
                if (getKey("isProxyLogin")) {
                    setKey('showBatch', customerMerchantWhenProxyLogin?.resources?.length > 0)
                }

                const enableTargetBalanceAccount = getCustomerConfigurationValue(customerConfigurations, 'ENABLE_TARGET_BALANCE_ACCOUNT');                    
                setKey('enableTargetBalanceAccount', enableTargetBalanceAccount === '1' || enableTargetBalanceAccount?.toLowerCase() === 'true' ? 'true':'false');
                

                const enableInvestmentAccount = getCustomerConfigurationValue(customerConfigurations, 'ENABLE_CASH_BUILDER_ACCOUNT');                    
                setKey('enableInvestmentAccount', enableInvestmentAccount === '1' || enableInvestmentAccount?.toLowerCase() === 'true' ? 'true':'false');
                
                const enableCashBuilderAccount = getCustomerConfigurationValue(customerConfigurations, 'ENABLE_CASH_BUILDER_PLUS_ACCOUNT');                    
                setKey('enableCashBuilderAccount', enableCashBuilderAccount === '1' || enableCashBuilderAccount?.toLowerCase() === 'true' ? 'true':'false');

                const cashBuilderPlusInvestmentMode = getCustomerConfigurationValue(customerConfigurations, 'CASH_BUILDER_PLUS_INVESTMENT_MODE');                
                setKey('cashBuilderPlusInvestmentMode', cashBuilderPlusInvestmentMode);

                const cashBuilderInterestRate = getCustomerConfigurationValue(customerConfigurations, 'CASH_BUILDER_INTEREST_RATE');                   
                setKey('cashBuilderInterestRate', cashBuilderInterestRate + '%');

                const cashBuilderPlusInterestRate = getCustomerConfigurationValue(customerConfigurations, 'CASH_BUILDER_PLUS_TIER_2_INTEREST_RATE');                    
                setKey('cashBuilderPlusInterestRate', cashBuilderPlusInterestRate + '%');
                
                const collectAchControl = getCustomerConfigurationValue(customerConfigurations, 'ALLOW_ACH_COLLECT');   
                setKey('collectAchControl', collectAchControl);

                const accountAgreementConfigurations = getCustomerConfigurationsByCategory(customerConfigurations, 'ACCOUNT');
                const debitCardAgreementConfigurations = getCustomerConfigurationsByCategory(customerConfigurations, 'DEBIT_CARD_CONFIGURATION_ENUM');
                const merchantAgreementConfigurations = getCustomerConfigurationsByCategory(customerConfigurations, 'MERCHANT');
                setKey('accountAgreementConfigurations', accountAgreementConfigurations);
                setKey('debitCardAgreementConfigurations', debitCardAgreementConfigurations);
                setKey('merchantAgreementConfigurations', merchantAgreementConfigurations);

                const enableFundingRule = getCustomerConfigurationValue(customerConfigurations, checkIsPartner()? "SHOW_FUNDING_RULE_PARTNER_PORTAL" :"SHOW_FUNDING_RULE_CUSTOMER_PORTAL");  
                setKey('enableFundingRule', enableFundingRule === '1' ? 'true' : 'false');

                setKey("isCardQuickSettleEnabled", getQuickSettleValue(quickSettleSettings,'COLLECT_CARD_QUICK_SETTLE'));
                setKey("isAchQuickSettleEnabled", getQuickSettleValue(quickSettleSettings,'COLLECT_ACH_QUICK_SETTLE'));
                setKey("isCheckQuickSettleEnabled", getQuickSettleValue(quickSettleSettings,'COLLECT_CHECK_QUICK_SETTLE'));
                
                setKey("isIssueDebitCardBusinessAllowed", getPmSettingsValue(pmSettings, "ALLOW_CORPORATE_PHYSICAL_DEBIT_CARD_ISSUANCE"));
                setKey("isIssueDebitCardIndividualAllowed", getPmSettingsValue(pmSettings, "ALLOW_CONSUMER_PHYSICAL_DEBIT_CARD_ISSUANCE"));
                setKey('ppiSupportedMethods', getPmSettingsValue(pmSettings, "ALLOWED_METHODS_IN_PPI"))
                setKey('oneTimeAllowed', getPmSettingsValue(pmSettings,"ALLOW_COLLECT_WITH_SOURCE_DETAILS"))
                setKey('sendViaVirtualMethod', getPmSettingsValue(pmSettings, 'ALLOW_SEND_VIA_VIRTUAL_CARD'))
                setKey("mandateFieldForCardTransaction", getPmSettingsValue(pmSettings, 'MANDATE_CARD_INFO_FOR_CARD_TRANSACTION'))
                setKey("isNewCardStatusLifecycleApplied", getPmSettingsValue(pmSettings, 'APPLY_NEW_CARD_STATUS_LIFECYCLE'))

                setKey("isInternalPMSettingEnabled", getPmSettingsValue(pmSettings,'INTERNAL_PROGRAM_MANAGER'));
                
                const mxcWhitelistedUsersList = getCustomerConfigurationValue(customerConfigurations,"MXC_WHITELISTED_USERS");
                setKey("mxcWhitelistedUsersList", mxcWhitelistedUsersList);

                setKey("sessionToken", sessionData);
                if (!sessionData) {
                    return false;
                }
                setKey('isPPIEnabled', 'TRUE')
                
                if (customerConfigurations) {
                    const transactionAprovalValue = getCustomerConfigurationValue(customerConfigurations, 'TRANSACTION_APPROVAL');
                    const enableBillPaymentVisible = getCustomerConfigurationValue(customerConfigurations, 'ALLOW_MONEYGRAM_TRANSACTION');
                    setKey('isTransactionApprovalEnabled', transactionAprovalValue === '1' || transactionAprovalValue?.toLowerCase() === 'true' ? 'true':'false');

                    if(enableBillPaymentVisible) {
                        setKey('isEnableBillPaymentVisible', enableBillPaymentVisible === '1' || enableBillPaymentVisible?.toLowerCase() === 'true' ? 'true':'false');
                    }
                }

                if (accountList && accountList.totalCount > 1) {
                    setKey("verificationStatus", 'true');
                    setKey("accountStatus", 'true');
                    if(accountList?.resources.find((x:any)=>x.type === 'CASH_BUILDER')){
                        // Set hasInvestmentAccount flag as true to check current user have investment account or not
                        updateComponentParams(dispatch, "customer-basic-info", { 'hasInvestmentAccount': true })
                    }
                    if(accountList?.resources.find((x:any)=>x.type === 'CASH_BUILDER_PLUS')){
                        // Set hasCashBuilderAccount flag as true to check current user have Cash Builder+ account or not
                        updateComponentParams(dispatch, "customer-basic-info", { 'hasCashBuilderAccount': true })
                    }
                }
                else if (accountList && accountList.totalCount === 1 && accountList?.resources[0]?.status === 'ACTIVE') {
                    setKey("accountStatus", 'true');
                }
                else {
                    setKey("accountStatus", 'false');
                }
                setKey("customerInitialAccount",accountList?.resources?.[0] );
                /**
                 * setting other varibales in storage which is used in regular login flow
                 */
                if (userTypeData?.type) {
                    setKey("userType", userTypeData?.type);
                }
                if (userTypeData?.status) {
                    setKey("customerStatus", userTypeData?.status);
                }
                if (userTypeData?.type === 'BUSINESS') {
                    setKey('mxmFastFunding',true);
                    setKey('doingBusinessAs', userTypeData?.business?.doingBusinessAs)
                    setKey("CustomerName", userTypeData?.business?.legalName);
                    setKey("businessCategory", userTypeData?.business?.businessCategory);
                    setVerificationStatus(userTypeData?.business?.verification);
                }
              
                else if (userTypeData?.type === 'INDIVIDUAL') {
                    setKey("CustomerIndividualFullName", userTypeData?.individual?.fullName);
                    setVerificationStatus(userTypeData?.individual?.verification);
                }

                else if (userTypeData?.type === 'JOINT_TENANCY') {
                  const authUserId = getKey("authUserId");
                  const customer = userTypeData.owners?.find((item: any) => item.id === authUserId);
                  const primaryOwner = userTypeData.owners?.find((item: any) => item.isPrimaryOwner);
                  setKey("CustomerIndividualFullName", customer?.fullName);
                  setKey("PrimaryOwnerFullName", primaryOwner?.fullName);
                  setKey("LoggedInOwnerId", customer?.id);
                  setVerificationStatus(userTypeData?.verification);
                }

                setKey("isUserDetailsAvailable", 'TRUE');
                getKYCDetails(accountList);
                updateComponentParams(dispatch, 'keycloak-post-login', { 'skipSkeleton': true, 'accountList': accountList, 'customerConfigurations': customerConfigurations })

            }).catch(error => {
                return { ...error };
            })

            //Set PPI Details
            const ppiDetails = await httpClient.get(getCustomerUrl('/ppi'));
            updateComponentParams(dispatch, "customer-basic-info", { 'ppiDetails': ppiDetails?.data });
            //uiFeat.
            return result
        } catch (e) {
            Logger.error("KeycloakPostLogin", "error", e);
            return null;
        }
    }
   
    
    /**
     * function to get session data from API
     * @returns data
     */
    function getSession() {
        return httpClient.get('/secure/sessiontoken');
    }
    /**
     * function to get account list from API
     * @returns data
     */
    function getUserAccount() {
        return httpClient.post('customer/id/' + customerId + '/account/list', listRequest);
    }

   /**
     * function to get readMe token from API
     * @returns data
     */
   function getReadmeToken(userName:any) {
    const readMelistRequest = {
        name:   userName,
        email:  userName,
        allowedProjects: readMeConfig['projects'], 
        apikey: readMeConfig['api_key'] ,
        version:  1
    }
    return httpClient.post('user/readmeToken', readMelistRequest);
  }

    /**
     * function to get processing settings data from API
     * @returns data
     */
    function getProcessingSettings() {
        return httpClient.post('programManager/getProgramManagerSetting', settingsReq);
    }
       /**
     * function to get processing settings data from API
     * @returns data
     */
       function getPMProcessingSettings() {
        return httpClient.post('programManager/getProgramManagerSetting', themeSettingsRequest);
    }
    /**
     * function to get customer data from API
     * @returns data
     */
    function getCustomerDetails() {
        return httpClient.get('customer/id/' + customerId);
    }

    /**
     * function to get PM level's quick settle settings data from API
     * @returns data
     */
    function getQuickSettleSettings() {
        return httpClient.post(`programManager/configuration/list`, quick_settle_settings_request);
    }

    /**
     * Function to check account status
     * @param accountList :account info
     */
    function getKYCDetails(accountList: any) {
        if (accountList && accountList.totalCount === 0) {
            setKey("customerKYC", 'continue');
        }
        else if (accountList && accountList.totalCount === 1 && accountList?.resources[0]?.status === 'INACTIVE') {
            setKey("customerKYC", 'stepper');
        }
        else {
            setKey("customerKYC", 'skip');
        }
    }

    /**
    * Function to get program manager setting value
    * @param data :settings data
    * @param settingName :settings name
    * @returns
    */
    function getPmSettingsValue(data: any, settingName: string) {
        const value = data?.resources?.find((x: any) => x.settingName === settingName)
        return value?.settingValue ?? value?.defaultValue;
    }     

    /**
     * Function to get customer configuraion value
     * @param data :merchant data
     * @param settingName :setting name
     * @returns
     */
      function getCustomerConfigurationValue(data:any, settingName: string) {
        const value = data?.resources?.find((x:any)=> x.name === settingName)

        return value?.value;
    }

  /**
   * Function to get customer configurations by category
   * @param data :configuration data
   * @param categoryName :category name
   * @returns filtered configurations
   */
  function getCustomerConfigurationsByCategory(data:any, categoryName: string) {
    return data?.resources?.filter((x:any)=> x.category === categoryName);
  }

    /**
     * Function to get quick settle value
     */
    function getQuickSettleValue(data:any, name: string) {
        return !!Number(data?.resources?.find((item:any)=> item.name === name)?.value);
    }

     /**
     * function to get customer configuration value
     * @returns data
     */
       function getCustomerConfiguration() {
        return httpClient.get('customer/id/' + customerId + '/configuration');
    }

    /**
     * function to get customer merchant
     * @returns data
     */
    function getCustomerMerchantWhenProxyLogin() {
        return getKey("isProxyLogin") ? httpClient.post(getCustomerUrl("/merchant/list", false), merchant_request) : [];
    }

    return (
        <DashboardSkeleton />
    )
};
